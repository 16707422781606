// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-partnerships-tsx": () => import("./../../../src/pages/partnerships.tsx" /* webpackChunkName: "component---src-pages-partnerships-tsx" */),
  "component---src-pages-presale-tsx": () => import("./../../../src/pages/presale.tsx" /* webpackChunkName: "component---src-pages-presale-tsx" */),
  "component---src-pages-underconstruction-tsx": () => import("./../../../src/pages/underconstruction.tsx" /* webpackChunkName: "component---src-pages-underconstruction-tsx" */)
}

